<template>
  <div class="page-layout page-layout--index">
    <HomepageSection
      class="no-padding"
      :class="getOrderClass(homepageSectionSlider)"
      :title="homepageSectionSlider.title"
      :content="homepageSectionSlider.content"
      :additional-text="homepageSectionSlider.additional_text"
    >
      <InformationContainer />
    </HomepageSection>

    <HomepageSection
      :class="getOrderClass(homepageSectionCategory)"
      :title="homepageSectionCategory?.title ?? t('homepage.offer')"
      :content="homepageSectionCategory?.content ?? t('homepage.insuranceCategories')"
      :additional-text="homepageSectionCategory?.additional_text ?? ''"
    >
      <CategoriesContainer />
    </HomepageSection>

    <HomepageSection
      v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_your_agent)"
      :class="getOrderClass(homepageSectionAgent)"
      :title="homepageSectionAgent.title ?? t('homepage.yourAgent')"
      :content="homepageSectionAgent.content"
      :additional-text="homepageSectionAgent.additional_text"
    >
      <template #content>
        <div class="typography-header__big-one">
          <span class="text-secondary" :class="{ 'display-block': windowWidth <= sm }">
            {{
              agentModel && agentModel.name.length > 1
                ? t("homepage.wantToDiscussWithAgent")
                : homepageSectionAgent.content ?? t("homepage.youDontHaveAgent")
            }}
          </span>
          <br v-if="windowWidth > sm">
          <span class="text-primary display-block center">
            {{
              agentModel && agentModel.name.length > 1
                ? t("homepage.writeOrPhoneWithAgent")
                : homepageSectionAgent.additional_text ?? t("homepage.contactWithUs")
            }}
          </span>
        </div>
      </template>
      <YourAgentContainer class="grid-order-5" />
    </HomepageSection>

    <HomepageSection
      :class="getOrderClass(homepageSectionRecommendation)"
      :title="homepageSectionRecommendation.title ?? t('homepage.recommendations')"
      :content="
        homepageSectionRecommendation.content ?? t('homepage.13kClientsTrustedUs')
      "
      :additional-text="homepageSectionRecommendation.additional_text"
    >
      <RecommendationsContainer />
    </HomepageSection>

    <HomepageSection
      :class="getOrderClass(homepageSectionNews)"
      :title="homepageSectionNews.title ?? t('homepage.news')"
      :content="homepageSectionNews.content ?? t('homepage.checkNews')"
      :additional-text="homepageSectionNews.additional_text"
    >
      <NewsContainer read-more class="no-padding" />
    </HomepageSection>
  </div>
</template>

<script setup lang="ts">
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';

import { showModal, useWindowWidth } from '~/composables';
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useBuyWizardFormStore } from '~/store/buy-wizard-form';

const { t } = useI18n();
const { windowWidth, sm } = useWindowWidth();
const assignedAgentStore = useAssignedAgentStore();
const agentModel = computed(() => assignedAgentStore.agent);

const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;

useHead({
  title: homepageData.seo_title,
  link: [{ rel: 'canonical', href: '/', id: 'canonical' }],
  meta: [
    {
      name: 'description',
      content: homepageData.seo_description,
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ],
});

const homepageSectionCategory = computed(
  () =>
    homepageData
        && homepageData.sections.find(v => v.name === 'Kategorie'),
);
const homepageSectionSlider = computed(
  () =>
    homepageData && homepageData.sections.find(v => v.name === 'Slider'),
);
const homepageSectionAgent = computed(
  () =>
    homepageData
        && homepageData.sections.find(v => v.name === 'Twój agent'),
);
const homepageSectionRecommendation = computed(
  () =>
    homepageData
        && homepageData.sections.find(v => v.name === 'Rekomendacje'),
);

const homepageSectionNews = computed(
  () =>
    homepageData
        && homepageData.sections.find(v => v.name === 'Aktualności'),
);

const getOrderClass = (section: { desktop_order: string; mobile_order: string }) => {
  const { desktop_order, mobile_order } = section;
  return `grid-order-${desktop_order} grid-mobile-order-${mobile_order}`;
};

const route = useRoute();
const router = useRouter();

if (route.query.show_agent_modal) {
  showModal('agentAliasInfo');
  router.replace({ query: {} });
}

const BuyWizardFormStore = useBuyWizardFormStore();

BuyWizardFormStore.setPrices({
  agent_code: agentModel.value && agentModel.code ? agentModel.code : 'some',
  // agent_code: 'KK123123',
  insurances: [
    {
      parameters: [],
    },
  ],
});

onMounted(() => {
  assignedAgentStore.refresh();
});

</script>

<route>
{
  name: "glowna",
  meta: {
    layout: 'default'
  }
}
</route>
