<template>
  <div v-if="!state.loading && state.approvals.length >= 1">
    <FormInputApproval
      v-if="primary && approvalVisibleCount > 1"
      :key="state.selectAllApproval.slug"
      v-model.lazy="state.selectAllApproval"
      :type="`${type}`"
      :input-type="state.selectAllApproval.input_type"
      :primary="true"
      :no-collapse="props.noCollapse"
      @change="onChangeSelectAllHandler"
    />

    <FormInputApproval
      v-for="(approval, index) in state.approvals"
      v-show="!approval.hide"
      :key="approval.slug"
      v-model.lazy="state.approvals[index]"
      :type="`${type}-${index + 1}`"
      :input-type="approval.input_type"
      :counter="index"
      :disabled="checkDisabled(approval)"
      :primary="false"
      :required="approval.required"
      :dirty="approval.hide || approval.checked || dirty"
      :no-collapse="props.noCollapse"
      @changed-vs="handleVsChange"
      @change="onChangeApprovalHandler"
    />

    <!-- text under checkboxes -->
    <span
      v-if="(!state.approvalsHideAll && state.approvals.length >= 1)"
      class="display-block text-secondary-55-percent approval-x"
      :class="{ 'display-none' : type === 'bwf' && !checkUnilinkNotRequiredApprovals() }"
    >
      {{ t('common.approvals*') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '~/store/error';
import { useAuthStore } from '~/store/auth';
import { useUserStore } from '~/store/user';
import { useBuyWizardFormStore } from '~/store/buy-wizard-form';
import { getApprovals } from '~/composables/approvalsKm';

interface Props {
  backendOnly: boolean;
  type: string;
  approvalType?: string;
  primary: boolean;
  filter?: string[];
  dirty?: boolean;
  noCollapse?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  backendOnly: false,
  type: 'index',
  approvalType: 'user-approval',
  primary: true,
  filter: () => [],
  dirty: false,
  noCollapse: false,
});
const { backendOnly, type, approvalType, primary, filter, dirty } = toRefs(props);

const BuyWizardFormStore = useBuyWizardFormStore();
const errorStore = useErrorStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const isLoggedIn = computed(() => authStore.getLoggedIn);
const userInfo = computed(() => userStore.getUserInfo);
const approvalsFromStore = computed(() => BuyWizardFormStore.getApprovals);

const { t } = useI18n();
const route = useRoute();

interface Approval {
  checked: boolean;
  title: string;
  content: string | null;
  internal_url: string | null;
  expandable: boolean;
  expanded: boolean;
  hide: boolean;
  required: boolean;
  input_type: string;
  provider: string;
  slug: string;
  type: string;
}

interface ValidationApproval {
  approvals: Array<boolean>;
}

interface State {
  loading: boolean;
  selectAllApproval: Approval;
  approvals: Array<Approval>;
  approvalsReq: Array<Approval>;
  approvalsHideAll: boolean;
  validations: ValidationApproval;
  validationsErrors: ValidationApproval;
  validationsOk: ValidationApproval;
  validationsAllOk: boolean;
  validationsSomeError: boolean;
}

const state: State = reactive({
  loading: true,
  selectAllApproval: {
    checked: false,
    title: t('inputs.selectAllApprovals'),
    content: null,
    internal_url: null,
    expandable: false,
    expanded: false,
    hide: false,
    required: false,
    input_type: 'check',
    provider: 'frontend',
    slug: `${approvalType.value}-all`,
    type: `${approvalType.value}`,
  },
  approvals: [],
  approvalsReq: [],
  approvalsHideAll: false,
  validations: {
    approvals: [],
  },
  validationsErrors: {
    approvals: [],
  },
  validationsOk: {
    approvals: [],
  },
  validationsAllOk: false,
  validationsSomeError: false,
});

// a computed ref
const isAllApprovalChecked = computed(() => {
  const ele = state.approvals.filter(v => !v.checked);
  return ele.length === 0;
});

const approvalVisibleCount = computed(() => {
  return state.approvals.filter(v => !v.hide).length;
});

const handleVsChange = (args) => {
  state.validations.approvals[args.counter] = args.vS;
  state.validationsOk.approvals[args.counter] = args.validationOk;
  state.validationsErrors.approvals[args.counter] = args.validationErrors;
};

const emit = defineEmits(['approvals', 'approvalsReq', 'displayApprovalStatement', 'validations']);

const checkAndHideApproval7And8 = () => {
  if (approvalType.value === 'nnw-school') {
    state.approvals.forEach((v) => {
      if (v.slug === 'approval-7' || v.slug === 'approval-8') {
        v.checked = true;
        v.hide = true;
      }
    });
  }
};

const filterApprovals = () => {
  state.approvalsReq = state.approvals.filter(v => v.required === true);

  if (filter.value && filter.value.length >= 1 && !isLoggedIn.value) {
    if (filter.value.length >= 1 && !route.query.uuid) {
      state.approvals = state.approvals.filter(v => !filter.value.includes(v.slug));
      state.loading = false;
    }
    if (filter.value.length >= 1 && route.query.uuid) {
      state.approvals = state.approvals.filter(v => !filter.value.includes(v.slug) && v.provider !== 'signal-iduna');
      state.loading = false;
    }
  }

  if (route.path === t('pages.insuranceEducationForm') && !route.query.uuid && approvalsFromStore.value && approvalsFromStore.value.length >= 1) {
    approvalsFromStore.value.forEach((v, i) => {
      if (state.approvals.findIndex(fi => fi.slug === v.slug) !== -1) {
        state.approvals[state.approvals.findIndex(fi => fi.slug === v.slug)].checked = v.checked;
      }
    });
  }

  if (isLoggedIn.value && userInfo.value) {
    userInfo.value.approvals.forEach((v, i) => {
      if (state.approvals.findIndex(fi => fi.slug === v.slug) >= 0) {
        state.approvals[state.approvals.findIndex(fi => fi.slug === v.slug)].hide = true;
        state.approvals[state.approvals.findIndex(fi => fi.slug === v.slug)].checked = true;
      }
    });
    if (!backendOnly.value && approvalType.value !== 'nnw-school' && state.approvals.filter(f => f.slug !== `${approvalType.value}-all`).every(v => v.hide === true)) {
      state.approvals[0].hide = true;
    }
    if (state.approvals.every(v => v.hide === true)
      && state.approvalsReq.every(v => v.hide === true)) {
      state.approvalsHideAll = true;
      state.validationsAllOk = true;
    }

    state.loading = false;
  }

  checkAndHideApproval7And8();

  state.loading = false;
};

const checkValidations = () => {
  const tableOfTruth: boolean[] = [];

  if (state.approvals.length === state.validationsOk.approvals.length) {
    state.validationsOk.approvals.forEach((v, i) => {
      if ((v === true && state.approvals[i].required)
        || (v === true && !state.approvals[i].required)
        || (v === false && !state.approvals[i].required)
      ) {
        tableOfTruth.push(true);
      } else if (v === false && state.approvals[i].required) {
        tableOfTruth.push(false);
      }
    });
  }

  state.validationsAllOk = tableOfTruth.length > 0 && tableOfTruth.every(v => v);

  state.validationsSomeError = state.validationsErrors.approvals.includes(true);

  emit('validations', {
    v$: state.validations.approvals,
    validationsAllOk: state.validationsAllOk,
    validationsSomeError: state.validationsSomeError,
  });

  errorStore.setErrorVs({
    isError: state.validationsSomeError,
    type: 'user-update-form',
  });
};

watch(state, () => {
  if (state.approvals.length > 1) {
    checkValidations();
  }

  if (state.approvals.length > 1 && state.loading === false) {
    emit('approvals', {
      approvals: state.approvals,
      approvalsReq: state.approvalsReq,
    });

    if (filter.value.includes('approval-9')) {
      emit('displayApprovalStatement', true);
    } else {
      const indexUni = state.approvals.findIndex(v => !v.required && v.provider === 'unilink' && v.checked);
      emit('displayApprovalStatement', indexUni !== -1 && !state.approvalsHideAll);
    }
  }

  checkAndHideApproval7And8();
});

const onChangeSelectAllHandler = () => {
  state.approvals.forEach(approval => approval.checked = state.selectAllApproval.checked);
};

const onChangeApprovalHandler = () => {
  state.selectAllApproval.checked = isAllApprovalChecked.value;
};

onBeforeMount(() => {
  state.loading = true;
  getApprovals(approvalType.value)
    .then((approvals) => {
      state.approvals = approvals;

      state.validationsOk.approvals = Array(state.approvals.length).fill(false);
      state.validationsErrors.approvals = Array(state.approvals.length).fill(false);

      filterApprovals();
    });
});

const checkDisabled = (approval) => {
  if (isLoggedIn.value && userInfo.value
    && approval.isChildren === true
    && route.path === t('pages.insuranceEducationForm')
    && userInfo.value.approvals.some(v => v.slug === approval.parent_slug)
  ) {
    return false;
  }

  if (approval.isChildren === true) {
    return state.approvals.some(v => v.slug === approval.parent_slug && !v.checked);
  }
};

const checkUnilinkNotRequiredApprovals = () => {
  return state.approvals.some(v => v.provider === 'unilink' && v.required === false && v.hide === false);
};
</script>
