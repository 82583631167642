import { acceptHMRUpdate, defineStore } from 'pinia'

interface Success {
  status: number
  statusText: string
  message: string
  type: string
}

export interface SuccessState {
  success: Success
}

export const useSuccessStore = defineStore('success', {
  state(): SuccessState {
    return {
      success: {
        status: 100,
        statusText: '',
        message: '',
        type: '',
      },
    }
  },
  actions: {
    setSuccess(value) {
      this.success = value
    },
  },
  getters: {
    getSuccess: (state: SuccessState) => state.success && state.success,
  },
  persist: false,
})

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useSuccessStore, import.meta.hot)) }
