import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Approval, DateAndCode, Insured, Insurer } from '~/store/nnw-school-step1';
import { nnwSchoolDateAndCodeDefault, nnwSchoolInsuredDefault, nnwSchoolInsurerDefault } from '~/store/nnw-school-step1';

export interface INnwSchoolForm {
  isset: boolean;
  dateAndCode: DateAndCode;
  approvals: Approval[];
  insured: Insured[];
  insurer: Insurer;
}

export const useNnwSchoolFormStore = defineStore('nnw-school-form', () => {
  const nnwSchoolForm = ref<INnwSchoolForm>({
    isset: false,
    dateAndCode: nnwSchoolDateAndCodeDefault,
    approvals: [],
    insured: [nnwSchoolInsuredDefault],
    insurer: nnwSchoolInsurerDefault,
  });

  return {
    nnwSchoolForm,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNnwSchoolFormStore, import.meta.hot));
}
