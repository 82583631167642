import { VueReCaptcha } from 'vue-recaptcha-v3'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  app.use(VueReCaptcha, {
    siteKey: import.meta.env.6Ld7H4sgAAAAAIPgePSvP5ijzw3gGCa1RNPoaxXO,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
}
