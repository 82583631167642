<template>
  <!-- AGENT ALIAS INFO -->
  <Modal
    v-model="isShowModal.agentAliasInfo"
    :close="() => closeModal('agentAliasInfo')"
  >
    <slot name="agentAliasInfo">
      <div class="modal-layout modal-agent-alias-layout">
        <button class="modal-layout__header--close modal-agent-alias-layout__header--close" @click="() => closeModal('agentAliasInfo')">
          <img
            src="@/assets/img/icons/modal-agent-alias-close.svg?url"
            alt="modal-agent-alias-close"
            title="close modal"
          >
        </button>
        <div class="modal-agent-alias-layout__header">
          <img
            src="@/assets/img/icons/modal-agent-alias-success.svg?url"
            alt="modal-agent-alias-success"
            title="fireworks"
            class="modal-agent-alias-layout__header--image"
          >
          <span class="modal-agent-alias-layout__header--text">
            {{ t('common.agentAliasInfo.header') }}
          </span>
        </div>
        <div class="modal-layout__content modal-agent-alias-layout__content">
          <span class="modal-agent-alias-layout__content--text">{{ t('common.agentAliasInfo.content') }}</span>
        </div>
        <div class="modal-layout__content modal-agent-alias-layout__content">
          <span class="modal-agent-alias-layout__content--text">{{ t('common.agentAliasInfo.content2') }}</span>
        </div>
      </div>
    </slot>
  </Modal>
</template>

<script setup lang="ts">
import { closeModal, isShowModal } from '~/composables';
const { t } = useI18n();
</script>
