<template>
  <div class="news-card" :class="{ 'news-card--related': related }">
    <RouterLink class="news-card__image" :to="`${t('pages.news')}/${data.slug}`">
      <picture v-if="!related && data.images.cover_image">
        <source :srcset="data.images.cover_image.medium_webp" type="image/webp" />
        <img
          class="news-card__image"
          :src="data.images.cover_image.medium_png"
          :alt="data.extras.cover_image_alt_attr"
          :title="data.title"
          loading="lazy"
        />
      </picture>
      <picture v-if="related && data.images.cover_image">
        <source :srcset="data.images.cover_image.large_webp" type="image/webp" />
        <img
          class="news-card__image"
          :src="data.images.cover_image.large_png"
          :alt="data.extras.cover_image_alt_attr"
          :title="data.title"
          loading="lazy"
        />
      </picture>
    </RouterLink>
    <div class="news-card__title">
      <RouterLink :to="`${t('pages.news')}/${data.slug}`" class="news-card__title--link">
        {{ data.title }}
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HomepageNews } from "~/types/cms";

interface Props {
  data: HomepageNews;
  related?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  related: false,
});
const { data, related } = toRefs(props);
const { t } = useI18n();
</script>
