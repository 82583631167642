<template>
  <HeaderDefault />
  <router-view v-bind="$attrs" />
  <div class="page-layout">
    <FooterContainer />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

const userStore = useUserStore();

onMounted(() => {
  userStore
    .refreshUserData();
});
</script>
