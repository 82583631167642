<template>
  <header
    class="header-layout"
    :class="[
      { 'header-layout--fixed' : scrollY > 50 },
      { 'header-layout--login-register': clientRoutes.includes(route.path) },
    ]"
  >
    <div class="header">
      <div class="header__logo">
        <RouterLink v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_all_logotype)" to="/">
          <img
            v-if="assignedAgentStore.agent?.id && assignedAgentStore.agent.show_partner_logotype && assignedAgentStore.agent.image.length"
            :src="assignedAgentStore.agent.image" alt="logo"
            class="header__logo--image"
            :class="{ 'header__logo--image-fixed' : scrollY > 50 }"
          >
          <img
            v-else
            src="@/assets/img/logo.png" alt="logo"
            class="header__logo--image"
            :class="{ 'header__logo--image-fixed' : scrollY > 50 }"
          >
        </RouterLink>
      </div>
      <div class="header__menu">
        <NavMenu v-if="!clientRoutes.includes(route.path)" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useWindowScrollY } from '~/composables';
const assignedAgentStore = useAssignedAgentStore();
const { scrollY } = useWindowScrollY();
const route = useRoute();
const { t } = useI18n();

const clientRoutes = ref([
  t('pages.clientLogin'),
  t('pages.clientRegister'),
  t('pages.forgotPassword'),
  t('pages.resetPassword'),
]);

</script>
