import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';

export interface DateAndCode {
  date: string;
  dateInput: string;
  discountCode: string;
}

export interface BuyWizardFormStep1 {
  approvals: object[];
  dateAndCode: DateAndCode;
  insurer: object;
  insured: object[];
  statement: object;
  validations: object;
  validationsOk: object;
  validationsAllOk: boolean;
}

export interface BuyWizardFormStep1Statement {
  options: string[];
  consent: boolean | null;
  validationOk: boolean;
  slug: string;
}

interface TransferMethodObject {
  id: string;
  name: string;
  banks: string;
  img: string;
  main_bank_id: string;
}

interface BuyWizardFormPayment {
  activeMethod: number;
  consent: boolean;
  cardNumber: string;
  cardDate: string;
  cardCode: string;
  blikCode: string;
  transferMethod: number;
  transferMethods: TransferMethodObject[];
  validationOk: boolean;
}

export interface BuyWizardFormChosenVariantParameters {
  name: string;
  parameter: string;
  checked: boolean;
}

interface BuyWizardFormChosenVariant {
  price: number;
  base_price: number;
  types: boolean[];
  discountCode: string | null;
  variant: string;
  parameters: BuyWizardFormChosenVariantParameters[];
}

interface BuyWizardPricesVariants {
  base_price: number;
  base_price_formatted: string;
  price: number;
  price_formatted: string;
  discounted_price?: number;
  discounted_price_formatted?: string;
  variant: string;
}

interface BuyWizardPrices {
  agent: boolean;
  variants: [
    BuyWizardPricesVariants[],
  ];
  insurances: BuyWizardPricesVariants[];
}

interface BuyWizardPricesInsurancesRequest {
  variant?: string | null;
  parameters: (string | false)[];
}

interface BuyWizardPricesRequest {
  agent_code: string | null;
  insurances: BuyWizardPricesInsurancesRequest[];
}

export interface EmailsSent {
  rodo: boolean;
  payment: boolean;
  certificate: boolean;
}

export interface FormState {
  approvals: object[];
  step1: BuyWizardFormStep1;
  step1Reset: boolean;
  prices: BuyWizardPrices;
  payment: BuyWizardFormPayment;
  activeStep: number;
  completedSteps: boolean[];
  chosenVariant: BuyWizardFormChosenVariant;
  totalPrice: number;
  emailsSent: EmailsSent;
}

export interface InsurancePriceParameters {
  name: string;
  parameter: string;
  checked: boolean;
}

export interface InsurancePrice {
  parameters: InsurancePriceParameters[];
  variant: string;
}

export const useBuyWizardFormStore = defineStore('buyWizardForm', {
  state(): FormState {
    return {
      approvals: [],
      activeStep: 1,
      completedSteps: [],
      emailsSent: {
        rodo: false,
        payment: false,
        certificate: false,
      },
      chosenVariant: {
        price: 36,
        base_price: 36,
        types: [false],
        discountCode: '',
        variant: 'nnw-school-1',
        parameters: [],
        // parameters: [{
        //   name: 'Szkoła sportowa / mundurowa',
        //   parameter: 'sport-school',
        //   checked: false,
        // }],
      },
      payment: {
        activeMethod: 0,
        consent: false,
        cardNumber: '',
        cardDate: '',
        cardCode: '',
        blikCode: '',
        transferMethod: 0,
        transferMethods: [],
        validationOk: false,
      },
      totalPrice: 0,
      prices: {
        variants: [[{}]],
        insurances: [{}],
        agent: false,
      },
      step1Reset: false,
      step1: {
        approvals: {},
        dateAndCode: {
          date: '',
          dateInput: '',
          discountCode: '',
        },
        insurer: {},
        insured: [{}],
        statement: {},
        validations: {},
        validationsOk: {},
        validationsAllOk: false,
      },
    };
  },
  actions: {
    setStep1(value: BuyWizardFormStep1) {
      this.step1 = value;
    },
    setStep1Insurance1Price(value: InsurancePrice) {
      this.step1.insured[0].variant = value.variant;
      this.step1.insured[0].parameters = value.parameters;
    },
    setStep1Statement(value: BuyWizardFormStep1Statement) {
      this.step1.statement = value;
    },
    setDiscountCode(value: string) {
      this.step1.dateAndCode.discountCode = value;
    },
    setChosenVariantDiscountCode(value: string | null) {
      this.chosenVariant.discountCode = value;
    },
    discountCodeReset() {
      this.step1.dateAndCode.discountCode = '';
      this.chosenVariant.discountCode = '';
      this.prices.agent = false;
    },
    setActiveStep(value: number) {
      this.activeStep = value;
    },
    setCompletedSteps(value: boolean[]) {
      this.completedSteps = value;
    },
    setPayment(value: BuyWizardFormPayment) {
      this.payment = value;
    },
    setChosenVariant(value: BuyWizardFormChosenVariant) {
      this.chosenVariant = value;
    },
    setTotalPrice(value: number) {
      this.totalPrice = value;
    },
    async setPrices(reqData: BuyWizardPricesRequest) {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/insurances/nnw-school/price`, {
          agent_code: reqData.agent_code,
          insurances: reqData.insurances,
        });
        this.prices = res.data.data;

        if (res.data.data.insurances.length === 1) {
          this.totalPrice = res.data.data.insurances[0].price_formatted.replace('.00', '');
        }

        if (res.data.data.insurances.length > 1) {
          let totalValue = 0;
          res.data.data.insurances.forEach((v) => {
            totalValue += Number(v.price_formatted);
          });
          this.totalPrice = totalValue;
        }

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    setApprovals(value: object[]) {
      this.approvals = value;
    },
    setEmailSent(type: string, value: boolean) {
      this.emailsSent[type] = value;
    },
    setStep1Reset(value: boolean) {
      this.step1Reset = value;
    },
    async resetState() {
      this.step1 = {
        approvals: {},
        dateAndCode: {
          date: '',
          discountCode: '',
        },
        insurer: {},
        insured: [{}],
        statement: {},
        validations: {},
        validationsOk: {},
        validationsAllOk: false,
      };
    },
  },
  getters: {
    getApprovals: (state: FormState) => state.approvals,
    getStep1: (state: FormState) => state.step1,
    getStep1Reset: (state: FormState) => state.step1Reset,
    getActiveStep: (state: FormState) => state.activeStep,
    getCompletedSteps: (state: FormState) => state.completedSteps,
    getEmailsSent: (state: FormState) => state.emailsSent,
    getPayment: (state: FormState) => state.payment,
    getChosenVariant: (state: FormState) => state.chosenVariant,
    getPrices: (state: FormState) => state.prices,
    getVariantsByIndex: (state: FormState) => (index: number) => state.prices.variants[index],
    getInsurancesPrices: (state: FormState) => state.prices.insurances,
    getTotalPrice: (state: FormState) => state.totalPrice,
    getDiscountCodeActivated: (state: FormState) => state.prices.agent,
    getDiscountCode: (state: FormState) => state.step1.dateAndCode.discountCode,
    getChosenVariantDiscountCode: (state: FormState) => state.chosenVariant.discountCode,
  },
  // persist: {
  //   storage: window.localStorage,
  // },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBuyWizardFormStore, import.meta.hot));
}
