<template>
  <div v-if="active" class="insurance-category-card insurance-category-card--active">
    <UiImgAgentMediaImage
      :src="data.images.main_image.svg"
      :src-agent="data.images.main_image_agent?.svg"
      :alt="data.extras.main_image_alt_attr"
      class="insurance-category-card__image"
      loading="lazy"
    />
    <span class="insurance-category-card__title"> {{ data.title }}</span>
    <span class="insurance-category-card__content"> {{ data.content }}</span>
    <div>
      <RouterLink
        :to="{ path: data.extras.text_link_url || '/' }"
        class="menu__link insurance-category-card__content-link"
      >
        {{ data.extras.text_link }}
      </RouterLink>
    </div>

    <div>
      <button
        :data-cy="data.extras.button_url"
        class="button button--primary button--radius button--category-card"
        @click="handleBuyNow(data.extras.button_url)"
      >
        <span class="text-bold text-button-link">{{ data.extras.button_text }}</span>
      </button>
    </div>
  </div>

  <div v-if="!active" class="insurance-category-card insurance-category-card--not-active">
    <UiImgAgentMediaImage
      :src="data.images.main_image.svg"
      :src-agent="data.images.main_image_agent?.svg"
      :alt="data.extras.main_image_alt_attr"
      class="insurance-category-card__image"
      loading="lazy"
    />
    <span class="insurance-category-card__title"> {{ data.title }}</span>
    <span class="insurance-category-card__content insurance-category-card__content--not-active">
      {{ data.content }}
    </span>
  </div>
</template>

<script setup lang="ts">
import type { HomepageOfferCard } from '~/types/cms';

export interface Props {
  data: HomepageOfferCard;
  active: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const { data, active } = toRefs(props);
const router = useRouter();

const handleBuyNow = (to: string) => {
  router.push(to);
};
</script>
