<template>
  <div class="form-container" :class="[mainClass, { 'form-container--agent-card': agentCard }]">
    <div class="form-container__u-icon" :class="{ 'form-container__u-icon--agent': agentCard }">
      <slot name="uIcon">
        <UiImgAgentSvg v-if="windowWidth >= sm && login" name="form-container/u-icon-login.svg" alt="u" />
        <UiImgAgentSvg v-if="windowWidth >= sm && !login" name="form-container/u-icon.svg" alt="u" />
      </slot>
    </div>

    <slot name="main">
      <div class="form-container__form" :class="slotClass">
        <slot />
      </div>
    </slot>
    <div class="form-container__i-icon" :class="{ 'form-container__i-icon-agent': agentCard }">
      <slot name="iIcon">
        <img v-if="windowWidth >= sm && !login" src="@/assets/img/form-container/i-icon.svg?url" alt="i">
        <img v-if="windowWidth >= sm && login" src="@/assets/img/form-container/i-icon-login.svg?url" alt="u">
        <img v-if="windowWidth < sm && mobileIIcon" src="@/assets/img/form-container/i-for-mobile.svg?url" alt="i">
      </slot>
    </div>

    <slot name="validation">
      <FormValidationFrame v-if="!noValidationFrame" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import { useWindowWidth } from '~/composables';

interface Props {
  mainClass?: string;
  slotClass?: string | object | string[];
  mobileIIcon?: boolean;
  login?: boolean;
  agentCard?: boolean;
  noValidationFrame?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mainClass: '',
  slotClass: '',
  mobileIIcon: false,
  login: false,
  agentCard: false,
  noValidationFrame: false,
});
const { mainClass, slotClass, mobileIIcon, login, agentCard, noValidationFrame } = toRefs(props);

const { windowWidth, sm } = useWindowWidth();
</script>
