<template>
  <a :href="`tel:${phoneFormatted}`" class="floating-action-button">
    <img src="@/assets/img/icons/call-calling.svg?url" alt="">
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  phone: String,
  default: () => '',
});

const phoneFormatted = computed(() => {
  return props.phone.replace(/[-\s]/g, '');
});

</script>
