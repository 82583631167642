<template>
  <div
    v-if="!shouldHideSocialMedia"
    class="footer-layout__socials-client--socials"
  >
    <LinkedInIcon
      v-if="pixelRatio === 1"
      class="footer-layout__socials-client--socials-icon"
      alt="linkedin"
      @click="handleOpenNewTab('linkedin')"
    />
    <YoutubeIcon
      v-if="pixelRatio === 1"
      class="footer-layout__socials-client--socials-icon"
      alt="youtube"
      @click="handleOpenNewTab('youtube')"
    />
    <FacebookIcon
      v-if="pixelRatio === 1"
      class="footer-layout__socials-client--socials-icon"
      alt="facebook"
      @click="handleOpenNewTab('facebook')"
    />

    <LinkedInIconSmall
      v-if="pixelRatio > 1"
      class="footer-layout__socials-client--socials-icon"
      alt="linkedin"
      @click="handleOpenNewTab('linkedin')"
    />
    <YoutubeIconSmall
      v-if="pixelRatio > 1"
      class="footer-layout__socials-client--socials-icon"
      alt="youtube"
      @click="handleOpenNewTab('youtube')"
    />
    <FacebookIconSmall
      v-if="pixelRatio > 1"
      class="footer-layout__socials-client--socials-icon"
      alt="facebook"
      @click="handleOpenNewTab('facebook')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { FacebookIcon, FacebookIconSmall, LinkedInIcon, LinkedInIconSmall, YoutubeIcon, YoutubeIconSmall } from '~/assets/img/icons/index.js';

const { pixelRatio } = useDevicePixelRatio();
const assignedAgentStore = useAssignedAgentStore();

const shouldHideSocialMedia = computed(() => {
  return assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_social_media;
});

function handleOpenNewTab(type: string) {
  const urls = {
    linkedin: 'https://pl.linkedin.com/company/unilink-sa',
    facebook: 'https://pl-pl.facebook.com/UnilinkSAcentrala/',
    youtube: 'https://www.youtube.com/channel/UCGFLI4WT4jeF5cIDywnOnhw',
  };

  window.open(urls[type], '_blank');
}
</script>
