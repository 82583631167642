<template>
  <div class="insurance-category-card insurance-category-card--not-found">
    <span class="insurance-category-card__title text-white text-wrap">
      {{ data.title }}
    </span>
    <span
      v-if="displayedPhone || displayedEmail"
      class="insurance-category-card__contact text-white text-wrap"
    >{{ data.content }}</span>
    <div
      v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address)"
      class="insurance-category-card__contact-details w-full text-center"
    >
      <a
        v-if="displayedPhone"
        :href="`tel:${displayedPhone}`"
        class="insurance-category-card__email text-secondary text-no-decoration"
        :class="{ 'text-wrap': displayedPhone && displayedPhone.length > 16 }"
      >
        {{ displayedPhone }}
      </a>
      <a
        v-if="displayedEmail"
        :href="`mailto:${displayedEmail}`"
        class="insurance-category-card__phone text-secondary text-no-decoration w-full"
        :class="{ 'truncate': displayedEmail && displayedEmail.length > 16 }"
      >
        {{ displayedEmail }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAssignedAgentStore } from '~/store/assigned-agent'
import type { HomepageInformationOfferCard } from '~/store/site-cms-content'

const assignedAgentStore = useAssignedAgentStore()

interface Props {
  data: HomepageInformationOfferCard;
}

const props = withDefaults(defineProps<Props>(), {});
const { data } = toRefs(props);

const displayedPhone = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_hotline;
  } else {
    return data.value.phone;
  }
});

const displayedEmail = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_email;
  } else {
    return data.value.email;
  }
});
</script>
