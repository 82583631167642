

import __layout_0 from '/var/node/app/src/layouts/default.vue'
export const layouts = {
'agent': () => import('/var/node/app/src/layouts/agent.vue'),
'client': () => import('/var/node/app/src/layouts/client.vue'),
'clientAuth': () => import('/var/node/app/src/layouts/clientAuth.vue'),
'default': __layout_0,
'error': () => import('/var/node/app/src/layouts/error.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
