import type { PluginOptions } from 'vue-toastification'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  const options: PluginOptions = {
    transition: 'Vue-Toastification__fade',
    maxToasts: 20,
    newestOnTop: true,
  }
  app.use(Toast, options)
}
