<template>
  <div v-if="agentCategories.length >= 1" class="insurance-categories">
    <CategoriesCard
      v-for="(category, index) in agentCategories"
      :key="index"
      :data="category"
      active
    />
    <CategoriesCard
      v-for="(inactiveCategory, inactiveCategoryIndex) in inactiveCategories"
      :key="inactiveCategoryIndex"
      :data="inactiveCategory"
      :active="false"
    />
    <CategoriesInfoCard
      v-for="(infoCategory, infoCategoryIndex) in informationCategories"
      :key="infoCategoryIndex"
      :data="infoCategory"
    />
  </div>
</template>

<script setup lang="ts">
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';
import { availableCategories } from '~/utils/categoriesFilter';
const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;

const agentCategories = computed(() => {
  const permissions = useAssignedAgentStore().agentPermissions;

  const filterByCategory = card => availableCategories.value.includes(card.category_slug);
  const filterByPermission = card => permissions.includes(card.slug);

  const offerCards = homepageData?.offer_cards ?? [];

  if (permissions) {
    return offerCards.filter(card => filterByPermission(card) && filterByCategory(card));
  } else {
    return offerCards.filter(filterByCategory);
  }
});

const informationCategories = homepageData?.information_offer_card && homepageData.information_offer_card;
</script>
