<template>
  <!-- VIVIAMO AGENT  -->
  <Modal
    v-model="isShowModal.viviamoAgentInfo"
    :close="() => closeModal('viviamoAgentInfo')"
  >
    <slot name="viviamoAgentInfo">
      <div class="modal-layout modal-vagent-alias-layout">
        <button class="modal-layout__header--close modal-vagent-alias-layout__header--close" @click="() => closeModal('viviamoAgentInfo')">
          <img
            src="@/assets/img/icons/modal-agent-alias-close.svg?url"
            alt="modal-vagent-close"
            title="close modal"
          >
        </button>
        <div class="modal-vagent-alias-layout__header">
          <span class="modal-vagent-alias-layout__header--text">
            {{ t('common.agentViviamoInfo.header') }}
          </span>
        </div>
        <div class="modal-layout__content modal-vagent-alias-layout__content">
          <span class="modal-vagent-alias-layout__content--text">{{ t('common.agentViviamoInfo.content') }}</span>
        </div>

        <div class="modal-vagent-alias-layout__images">
          <img
            src="@/assets/img/logo.png"
            alt="modal-vagent-success"
            title="fireworks"
            class="modal-vagent-alias-layout__header--image"
          >          <img
            src="@/assets/img/viviamo-logo.png"
            alt="modal-vagent-success"
            title="fireworks"
            class="modal-vagent-alias-layout__header--image"
          >
        </div>
      </div>
    </slot>
  </Modal>
</template>

<script setup lang="ts">
import { closeModal, isShowModal } from '~/composables';
const { t } = useI18n();
</script>
