import { helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { emailValidator } from '~/composables'

export const yourAgentFormRules = () => {
  const { withMessage } = helpers
  const { t } = useI18n()

  return {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(45),
    },
    phone: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(15),
    },
    email: {
      required,
      email: withMessage(t('validations.email'), emailValidator),
    },
    message: {
      required,
      minLength: minLength(20),
      maxLength: maxLength(1500),
    },
  }
}
