import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import type { LocationQueryValue } from 'vue-router';
import { useAgentStore } from './agent';

interface AuthState {
  token: string | null;
  status: string | null;
  tokenType: string | null;
  isLoggedIn: boolean | null;
}

interface RegisterRequest {
  name: string | null;
  surname: string | null;
  pesel: string | null;
  email: string;
  phone_prefix: string | null;
  phone_number: string | null;
  password: string;
  approvals: string[];
  orderUuid: string;
}

interface LoginRequest {
  email: string;
  password: string;
  remember: boolean;
  orderUuid?: string | null | undefined;
}

interface ForgotPasswordRequest {
  email: string;
}

interface ResetPasswordRequest {
  token: string;
  email: string;
  password: string;
  orderId?: string | LocationQueryValue[] | undefined;
}

export const checkLocalStorageAuthAndGetData = (type) => {
  return window.localStorage.getItem('auth')
    ? JSON.parse(window.localStorage.getItem('auth'))[type]
    : '';
};

export const useAuthStore = defineStore('auth', {
  state(): AuthState {
    return {
      token: checkLocalStorageAuthAndGetData('token'),
      status: checkLocalStorageAuthAndGetData('status'),
      tokenType: checkLocalStorageAuthAndGetData('tokenType'),
      isLoggedIn: checkLocalStorageAuthAndGetData('isLoggedIn'),
    };
  },
  actions: {
    async register(reqData: RegisterRequest) {
      try {
        const requestData = {
          email: reqData.email,
          password: reqData.password,
          approvals: reqData.approvals,
          order_uuid: reqData.orderUuid,
          ...(reqData.orderUuid === '' && {
            name: reqData.name,
            surname: reqData.surname,
            pesel: reqData.pesel,
            phone_prefix: reqData.phone_prefix,
            phone_number: reqData.phone_number,
            email: reqData.email,
          }),
        };

        const res = await axios.post(`${import.meta.env./api}/v1/register`, requestData);

        if (res.data.access_token) {
          this.token = res.data.access_token;
          this.status = res.data.status;
          this.tokenType = res.data.token_type;
          this.isLoggedIn = true;
        }

        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async login(reqData: LoginRequest) {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/login`, {
          email: reqData.email,
          password: reqData.password,
          remember: reqData.remember,
          order_uuid: reqData.orderUuid,
        });
        this.token = res.data.access_token;
        this.status = res.data.status;
        this.tokenType = res.data.token_type;
        this.isLoggedIn = res.data.access_token !== '';
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async logout() {
      if (this.token) {
        try {
          await axios.post(`${import.meta.env./api}/v1/logout`, {});
        } catch (error) {
          console.error(error);
        }
      }

      if (window.localStorage.getItem('auth')) {
        window.localStorage.removeItem('auth');
      }
      this.$reset();
    },
    async forgotPassword(reqData: ForgotPasswordRequest) {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/forgot-password`, {
          email: reqData.email,
          // eslint-disable-next-line no-prototype-builtins
          ...(reqData.hasOwnProperty('orderId') && reqData.orderId !== '' && {
            order_uuid: reqData.orderId,
          }),
        });
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async resetPassword(reqData: ResetPasswordRequest) {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/reset-password`, {
          token: reqData.token,
          email: reqData.email,
          password: reqData.password,
        });
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async resendVerificationEmail() {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/verify-email-resend`, {});
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getToken: (state: AuthState) => state.token,
    getStatus: (state: AuthState) => state.status,
    getTokenType: (state: AuthState) => state.tokenType,
    getLoggedIn: (state: AuthState) => state.isLoggedIn,
  },
  persist: {
    storage: window.localStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

axios.interceptors.request.use((config) => {
  const agentStore = useAgentStore();
  const authStore = useAuthStore();

  if (agentStore.isLoggedIn) {
    config.headers.Authorization = `Bearer ${agentStore.token}`;
    config.withCredentials = true;
  } else if (authStore.isLoggedIn) {
    config.headers.Authorization = `Bearer ${authStore.getToken}`;
    config.withCredentials = true;
  }
  return config;
});
