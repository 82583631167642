<template>
  <div class="radio-control-container">
    <label :for="`${type}-option1`" class="control">
      <input
        :id="`${type}-option1`"
        v-model.lazy="inputValue"
        type="radio"
        :value="true"
        required
        :disabled="disabled"
      >
      <div
        class="control__indicator
        control__indicator--radio"
      />
      <span
        class="radio-control-container__label"
        :class="{ 'approval__content--disabled': disabled }"
      >
        {{ options[0] }}
      </span>
    </label>

    <label :for="`${type}-option2`" class="control">
      <input
        :id="`${type}-option2`"
        v-model.lazy="inputValue"
        type="radio"
        :value="false"
        required
        :disabled="disabled"
      >
      <div
        class="control__indicator
      control__indicator--radio"
      />
      <span
        class="radio-control-container__label"
        :class="{ 'approval__content--disabled': disabled }"
      >
        {{ options[1] }}
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
interface Props {
  options: string[]
  modelValue: boolean | null
  type: string
  disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})
const { options, type, disabled, modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue', 'changedState'])

const inputValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

watch(() => disabled.value, (nV, oV) => {
  if (nV !== oV && nV === true) {
    emit('update:modelValue', null)
  }
})
</script>
