import axios from 'axios';
import { useErrorStore } from '~/store/error';

export const getApprovals = (approvalType: string) => {
  const sortBySlug = (a, b) => {
    return (Number(a.slug.match(/(\d+)/g)[0]) - Number((b.slug.match(/(\d+)/g)[0])));
  }

  const errorStore = useErrorStore()
  return axios
    .post(`${import.meta.env./api}/v1/approvals`, {
      approval_type: approvalType,
    })
    .then((res) => {
      const approvalsFromApi = res.data.data.filter((v) => v.group === 'consent');
      const approvalsChildrens = []
      approvalsFromApi.forEach((v) => {
        if (v.children.length >= 1) {
          v.children.forEach((vc) => {
            vc.isChildren = true
            vc.parent_slug = v.slug
            approvalsChildrens.push(vc)
          })
        }
      })

      const approvalsTemp = [...approvalsFromApi, ...approvalsChildrens].sort(sortBySlug)
      approvalsTemp.forEach((v) => {
        v.checked = false
        v.expandable = v.content !== null
        v.expanded = false
        v.hide = false
        if (
          approvalType === 'contact-form-approval'
          || approvalType === 'user-approval'
          || (approvalType === 'nnw-school' && v.input_type === 'radio' && v.provider === 'unilink')
        ) {
          v.input_type = 'check'
        }

        if (v.input_type === 'radio') {
          v.checked = null
        }
      })

      return Promise.resolve(approvalsTemp)
    })
    .catch((error) => {
      console.error(error)
      errorStore.setErrorReq({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
        type: approvalType,
      })
      return Promise.reject(error)
    })
}
