import { defineStore } from 'pinia';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { IAgent, IAgentsState } from '~/types/agent';
import { hexToRgb, shadeColor } from '~/utils/color';

export const useAssignedAgentStore = defineStore('agents', {
  state(): IAgentsState {
    return {
      refreshed: false,
      initialized: false,
      loaded: false,
      agentModel: null,
    };
  },
  actions: {
    setBodyVal() {
      const agentCode = this.agentCode;
      const agentColor = this.agentPrimaryColor;

      if (agentColor) {
        const shadedColor = shadeColor(agentColor, 80);
        const rgb = hexToRgb(agentColor);

        const rgbs = rgb ? `${rgb.r}, ${rgb.g}, ${rgb.b}` : null;

        // Ustawienie podstawowych kolorów
        document.documentElement.style.setProperty('--primary-color', agentColor);
        document.documentElement.style.setProperty('--color-primary-light-1', agentColor);
        document.documentElement.style.setProperty('--color-read-only-2', shadedColor);

        // Używanie pętli do ustawienia gradientów i kolorów RGB
        for (let i = 1; i <= 8; i++) {
          document.documentElement.style.setProperty(`--primary-gradient-${i}`, agentColor);
          if (rgbs) {
            document.documentElement.style.setProperty(`--primary-gradient-${i}-rgb`, rgbs);
          }
        }
      }
      if (agentCode) {
        document.body.dataset.agent = agentCode;
        document.body.classList.add('partner');
      } else {
        document.body.dataset.agent = 'none';
      }
    },
    async fetchUserAgent() {
      try {
        const response = await axios.get(`${import.meta.env./api}/v1/user/agent`);
        this.setAgent(response.data.data);
        this.setBodyVal();
        return 'user-agent';
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 404) {
          this.loaded = true;
        }
        if (axiosError.response && axiosError.response.status !== 500) {
          return 'error';
        }
      }

      return 'false';
    },
    async fetchAgent(code: string, permission: string) {
      try {
        const response = await axios.post(`${import.meta.env./api}/v1/agents`, {
          agent_code: code,
          permission,
        });
        this.setAgent(response.data.data);
        this.setBodyVal();

        return 'agent';
      } catch (error) {
        this.reset();
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 404) {
          this.loaded = true;
        }
        if (axiosError.response && axiosError.response.status !== 500) {
          return this.fetchUserAgent();
        }
      }

      return 'error';
    },
    refresh() {
      if (!this.agentModel || this.refreshed) {
        return;
      }

      return axios
        .post(`${import.meta.env./api}/v1/agents`, {
          agent_code: this.agentModel.code,
        })
        .then((response) => {
          this.setAgent(response.data.data);
          this.setBodyVal();
        });
    },
    setAgent(agent: IAgent) {
      this.agentModel = agent;
      this.initialized = true;
      this.refreshed = true;
      this.loaded = true;
    },
    reset() {
      this.agentModel = null;
      this.setBodyVal();
    },
  },
  getters: {
    agent: (state: IAgentsState) => state.agentModel,
    agentPermissions: (state: IAgentsState) => state.agentModel && state.agentModel.permissions,
    agentCode: (state: IAgentsState) => state.agentModel && state.agentModel.code,
    agentPrimaryColor: (state: IAgentsState) => state.agentModel && state.agentModel.primary_color,
    agentAlias: (state: IAgentsState) => state.agentModel && state.agentModel.alias,
    agentId: (state: IAgentsState) => state.agentModel && state.agentModel.id,
  },
  persist: {
    storage: window.localStorage,
    paths: ['initialized', 'agentModel'],
  },
});
