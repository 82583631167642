<template>
  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="10"
    :speed="150"
    :parallax="true"
    :zoom="false"
    :navigation="false"
    :pagination="{ clickable: true }"
    :effect="'fade'"
    :fade-effect="{ crossFade: true }"
    class="information-layout__slider"
    :class="{ 'cursor-default': swiperDataFiltered.length === 1 }"
    @slide-change-transition-start="onSlideChange"
  >
    <swiper-slide v-for="(data, index) in swiperDataFiltered" :key="index">
      <InformationCard :key="data.title" :data="data" />
    </swiper-slide>
  </swiper>
</template>

<script  lang="ts">
import { A11y, EffectFade, Navigation, Pagination, Parallax } from 'swiper';
import { useSwiper, useSwiperSlide } from 'swiper/vue';
import { useWindowWidth } from '~/composables';
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';
import { availableCategories } from '~/utils/categoriesFilter';

export default {
  name: 'InformationContainer',
  setup() {
    const { windowWidth, sm } = useWindowWidth();
    const swiper = useSwiper();
    const swiperSlide = useSwiperSlide();
    const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;
    const swiperData = computed(() => homepageData.sliders);
    const slideChanged = ref(true);

    /*
     * Hide slider categories depended on agent permissions
     */
    const permissions = useAssignedAgentStore().agentPermissions;
    const swiperDataFiltered = computed(() => {
      if (!swiperData) {
        return [];
      }

      const filterByCategory = item => availableCategories.value.includes(item.category_slug);

      const filterByPermissions = (val) => {
        if (!val.extras) {
          return true;
        }

        const { button_url } = val.extras;

        if (!button_url) {
          return true;
        }

        const permissionMap = {
          '/nnw-szkolne': 'nnw-school',
          '/turystyczne': 'travel',
        };

        return permissions?.includes(permissionMap[button_url]) ?? true;
      };

      return swiperData.value
        .filter(filterByPermissions)
        .filter(filterByCategory);
    });

    const onSlideChange = () => {
      slideChanged.value = !slideChanged.value;
    };

    const handleSlideChanged = (value) => {
      slideChanged.value = value;
    };

    return {
      swiper,
      swiperSlide,
      modules: [EffectFade, Navigation, Pagination, Parallax, A11y],
      windowWidth,
      sm,
      swiperData,
      swiperDataFiltered,
      onSlideChange,
      slideChanged,
      handleSlideChanged,
    };
  },
};
</script>
