
export function isSomeRouteActive(name: string): boolean {
  const route = useRoute();
  if (name === 'home') {
    return route.path === '/';
  } else {
    return route.path.includes(name);
  }
}

export function isRouteStartsWith(name: string) {
  const route = useRoute();
  if (name === '/home') {
    return route.path === '/';
  } else {
    const currentPath = route.path;
    return currentPath === name || (currentPath.startsWith(name) && currentPath[name.length] === '/');
  }
}

export function generateSlugFromTitle(title: string) {
  let slug = '';
  // Change to lower case
  const titleLower = title.toLowerCase();
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd');
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '');
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-');

  return slug;
}
