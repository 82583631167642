<template>
  <div v-if="state.messageSent" class="login-register-form__email-verification">
    <img src="@/assets/img/icons/verification-email-icon.svg?url" alt="checked">
    <span v-html="t('common.contactFormMessageSent')" />
  </div>
  <div v-else class="your-agent-form">
    <div class="your-agent-form__inputs">
      <span class="input--label">{{ t('inputs.name') }}</span>
      <div class="input--form-container">
        <input
          id="yourAgent-name"
          v-model.lazy="state.name"
          name="yourAgent-name"
          autocomplete="do-not-autocomplete"
          class="input input--fluid"
          :placeholder="t('inputs.enterName')"
          :class="{'input--invalid' : v$.name.$error }"
          required
          minlength="2"
          maxlength="50"
          @keypress="lettersKeypress($event)"
          @blur="v$.name.$touch()"
        >
        <FormValidationIcon
          :success="v$.name.$dirty && !v$.name.$error"
          :error="v$.name.$error"
          type="name"
          @clear-error="handleClearError"
        />
        <FormValidationMessage
          v-if="v$.name.$error"
          id="contacter-name-validation"
        >
          {{ t('validations.name') }}
        </FormValidationMessage>
      </div>
    </div>

    <div class="your-agent-form__inputs">
      <span class="input--label">{{ t('inputs.phone') }}</span>
      <div class="input--form-container">
        <input
          id="yourAgent-phone"
          v-model.lazy="state.phone"
          v-mask="'+48 ###-###-###'"
          name="yourAgent-phone"
          type="tel"
          class="input input--fluid"
          :placeholder="t('inputs.enterPhone')"
          :class="{'input--invalid' : v$.phone.$error }"
          required
          minlength="15"
          maxlength="15"
          @keypress="numberKeypress($event)"
          @blur="v$.phone.$touch()"
        >
        <FormValidationIcon
          :success="v$.phone.$dirty && !v$.phone.$error"
          :error="v$.phone.$error"
          type="phone"
          @clear-error="handleClearError"
        />
        <FormValidationMessage
          v-if="v$.phone.$error"
          id="contracter-phone-validation"
        >
          {{ t('validations.phone') }}
        </FormValidationMessage>
      </div>
    </div>

    <div class="your-agent-form__inputs">
      <span class="input--label">{{ t('inputs.email') }}</span>
      <div class="input--form-container">
        <input
          id="yourAgent-email"
          v-model.lazy="state.email"
          name="yourAgent-email"
          autocomplete="email"
          class="input input--fluid"
          :placeholder="t('inputs.enterEmail')"
          :class="{'input--invalid' : v$.email.$error }"
          required
          minlength="5"
          maxlength="250"
          @keypress="emailKeypress($event)"
          @blur="v$.email.$touch()"
        >
        <FormValidationIcon
          :success="v$.email.$dirty && !v$.email.$error"
          :error="v$.email.$error"
          type="email"
          @clear-error="handleClearError"
        />
        <FormValidationMessage
          v-if="v$.email.$error"
          id="contracter-email-validation"
        >
          {{ emailValidationMsg }}
        </FormValidationMessage>
      </div>
    </div>

    <div class="your-agent-form__inputs">
      <span class="input--label">{{ t('inputs.message') }}</span>
      <div class="input--form-container">
        <textarea
          id="contactMessage"
          v-model.lazy="state.message"
          name="contactMessageInput"
          class="input input--fluid textarea"
          :placeholder="t('inputs.enterMessage')"
          :class="{'input--invalid' : v$.message.$error }"
          required
          minlength="20"
          maxlength="1500"
          @blur="v$.message.$touch()"
        />
        <FormValidationIcon
          :success="v$.message.$dirty && !v$.message.$error"
          :error="v$.message.$error"
          type="message"
          @clear-error="handleClearError"
        />
        <FormValidationMessage
          v-if="v$.message.$error"
          id="contacter-message-validation"
          your-agent
        >
          {{ t('validations.message') }}
        </FormValidationMessage>
      </div>
    </div>

    <FormInputApprovals
      primary
      :backend-only="false"
      :category-slug="false"
      approval-type="contact-form-approval"
      type="index"
      @approvals="handleApprovals"
      @validations="handleApprovalsValidations"
      @display-approval-statement="handleDisplayApprovalStatement"
    />

    <div v-if="state.approvalStatement" class="text-extra-small text-secondary-55-percent">
      <span>{{ t('common.approvals*-statement-info') }}</span>
      <a :href="t('common.approvals*-statement-info-to')" target="_blank" class="link link--primary">{{ t('common.approvals*-statement-info-link') }}</a>
    </div>

    <button
      :disabled="!state.validationsAllOk"
      class="your-agent-form__button button button--primary button--radius button--information-buy"
      @click="sendYourAgentForm"
    >
      <span class="text-bold">{{ t('button.send2') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import axios from 'axios'
import type { ReCaptchaInstance } from 'recaptcha-v3'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { yourAgentFormRules } from './YourAgentFormRules'
import { emailKeypress, formatPhoneForApi, getEmailFromI18n, lettersKeypress, numberKeypress, useClearError } from '~/composables'
import { useErrorStore } from '~/store/error'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $recaptcha: (action: string) => Promise<string>
    $recaptchaLoaded: () => Promise<boolean>
    $recaptchaInstance: ReCaptchaInstance
  }
}
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
const { t } = useI18n()
const errorStore = useErrorStore()
const emit = defineEmits(['messageSent'])

const state = reactive({
  name: '',
  phone: '',
  email: '',
  message: '',
  messageSent: false,
  approvals: [],
  approvalsReq: [],
  approvalStatement: false,
  validations: {
    approvals: [],
  },
  validationsOk: {
    approvals: false,
  },
  validationsErrors: {
    approvals: false,
  },
  validationsSomeError: false,
  validationsAllOk: false,
})

const emailValidationMsg = getEmailFromI18n('validation').email

const rules = yourAgentFormRules()
const v$ = useVuelidate(rules, state, { $autoDirty: true })

const handleVSChange = (value) => {
}

const handleClearError = (args) => {
  useClearError(args, state, v$, null, true)
}

const handleApprovals = (args) => {
  // state.approvals = approvals
  state.approvals = args.approvals
  state.approvalsReq = args.approvalsReq
}

const handleApprovalsValidations = (args) => {
  state.validations.approvals = args.v$
  state.validationsOk.approvals = args.validationsAllOk
  state.validationsErrors.approvals = args.validationsSomeError
}

const handleDisplayApprovalStatement = (value) => {
  state.approvalStatement = value
}

const checkValidations = () => {
  state.validationsAllOk = state.validationsOk.approvals === true
    && (v$.value.name.$dirty && v$.value.name.$errors.length === 0)
    && (v$.value.email.$dirty && v$.value.email.$errors.length === 0)
    && (v$.value.phone.$dirty && v$.value.phone.$errors.length === 0)
    && (v$.value.message.$dirty && v$.value.message.$errors.length === 0)

  state.validationsSomeError
    = state.validationsErrors.approvals === true
    || (v$.value.name.$dirty && v$.value.name.$errors.length > 0)
    || (v$.value.phone.$dirty && v$.value.phone.$errors.length > 0)
    || (v$.value.email.$dirty && v$.value.email.$errors.length > 0)
    || (v$.value.message.$dirty && v$.value.message.$errors.length > 0)
  errorStore.setErrorVs({
    isError: state.validationsSomeError,
    type: 'contact-form',
  })
}

const recaptcha = async () => {
  // Wait until recaptcha has been loaded.
  await recaptchaLoaded()

  // Execute reCAPTCHA with action "contactForm'".
  return await executeRecaptcha('contactForm')
}

const sendYourAgentForm = async () => {
  const token = await recaptcha()

  await axios.post(`${import.meta.env./api}/v1/contact`, {
    name: state.name,
    phone: formatPhoneForApi(state.phone),
    email: state.email,
    message: state.message,
    approvals: state.approvals.filter(fv => fv.checked && fv.slug !== 'contact-form-approval-all').map(v => v.slug),
    recaptcha_token: token,
  }).then((res) => {
    state.messageSent = true
  })
}

watch(() => state, () => {
  if (state.approvals.length > 1) {
    checkValidations()
  }
  emit('messageSent', state.messageSent)
}, { deep: true })

watch(v$, () => {
  if (state.approvals.length > 1) {
    checkValidations()
  }
}, { deep: true })

</script>
