<template>
  <div class="main-layout index-component-layout">
    <div class="typography-header no-padding no-margin">
      <h2 class="typography-header__small-one text-primary text-bold">
        {{ props.title }}
      </h2>
      <slot name="content">
        <h3 class="typography-header__big-one text-secondary">
          {{ props.content }}
        </h3>
      </slot>
      <p>
        {{ props.additionalText }}
      </p>
    </div>
    <slot/>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  content: {
    type: String,
    required: false,
  },
  additionalText: {
    type: String,
    required: false,
  },

})
</script>
