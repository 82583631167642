import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import type { ComputedRef } from 'vue';
import type { Approval } from '~/types/approval';

export const useApprovalStore = defineStore('approval', () => {
  const allApprovals = ref<Array<Approval>>([]);
  const type = ref<String>('');

  const loadApprovals = (approvalType: string) => {
    if (type.value === approvalType) {
      return;
    }

    return axios
      .post(`${import.meta.env./api}/v1/approvals`, {
        approval_type: approvalType,
      })
      .then((res) => {
        type.value = approvalType;
        allApprovals.value = res.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const headerApprovals: Array<Approval> | ComputedRef = computed(() => allApprovals.value.filter((v: Approval) => v.group === 'header'));
  const consentApprovals: Array<Approval> | ComputedRef = computed(() => allApprovals.value.filter((v: Approval) => v.group === 'consent'));
  const footerApprovals: Array<Approval> | ComputedRef = computed(() => allApprovals.value.filter((v: Approval) => v.group === 'footer'));

  return {
    headerApprovals,
    consentApprovals,
    footerApprovals,
    allApprovals,
    loadApprovals,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApprovalStore, import.meta.hot));
}
