export const isShowModal = reactive({
  cpDeleteUser: false,
  nwwSchoolVariantsTable: false,
  agentAliasInfo: false,
  viviamoAgentInfo: false,
});

export const showModal = (key: 'contact' | 'cpDeleteUser' | 'nwwSchoolVariantsTable' | 'agentAliasInfo' | 'viviamoAgentInfo') => {
  isShowModal[key] = true;
};
export const closeModal = (key: 'contact' | 'cpDeleteUser' | 'nwwSchoolVariantsTable' | 'agentAliasInfo' | 'viviamoAgentInfo') => {
  isShowModal[key] = false;
};
