import { acceptHMRUpdate, defineStore } from 'pinia';
import countriesJson from '~/components/nnw/wizard/common/countries.json';
import type { InsurancePrice } from '~/store/buy-wizard-form';

export interface Approval {
  checked: boolean;
  title: string;
  content: string | null;
  internal_url: string | null;
  expandable: boolean;
  expanded: boolean;
  required: boolean;
  input_type: string;
  provider: string;
  slug: string;
  type: string;
}

export interface DateAndCode {
  date: string;
  discountCode: string;
}

export interface Country {
  id: string;
  name: string;
  code: string | null;
  currency: string | null;
  active: string;
  created_at: string;
  updated_at: string;
}

export interface City {
  id: number;
  city: string;
  created_at: string;
  updated_at: string;
  postal_code: string;
}

export interface CityMinimal {
  city: string;
}

export interface Insurer {
  name: string;
  surname: string;
  withoutPesel: boolean;
  pesel: string;
  dateOfBirth: string;
  dateOfBirthInput: string;
  email: string;
  phone: string;
  country: Country;
  city: City | CityMinimal;
  zipCode: string;
  post: string;
  street: string;
  houseNr: string;
  apartmentNr: string;
  correspondenceAddress: boolean;
  correspondenceCountry: Country;
  correspondenceZipCode: string;
  correspondenceCity: Object;
  correspondencePost: string;
  correspondenceStreet: string;
  correspondenceHouseNr: string;
  correspondenceApartmentNr: string;
}

export interface InsuredParameters {
  checked: boolean;
  name: string;
  parameter: string;
}

export interface Insured {
  id: number;
  name: string;
  surname: string;
  withoutPesel: boolean;
  pesel: string;
  dateOfBirth: string;
  dateOfBirthInput: string;
  parameters: InsuredParameters[];
  types: boolean[];
  country: Country;
  variant: string;
  sameAddressAsInsurer: boolean;
  city: City | CityMinimal;
  zipCode: string;
  post: string;
  street: string;
  houseNr: string;
  apartmentNr: string;
}

export interface SignalStatement {
  options: string[];
  consent: boolean | null;
  validationOk: boolean;
  approvalStatement: boolean;
  slug: string;
}

export interface Statement {
  slug: string;
}

export interface BuyWizardFormStep1 {
  isset: boolean;
  approvals: Approval[];
  insured: Insured[];
  signalStatement: SignalStatement;
  statement: Statement;
  dateAndCode: DateAndCode;
  insurer: Insurer;
  validations: object;
  validationsOk: object;
  validationsAllOk: boolean;
}

export interface BuyWizardFormStep1Statement {
  options: string[];
  consent: boolean | null;
  validationOk: boolean;
  slug: string;
}

export interface FormState {
  step1: BuyWizardFormStep1;
}

export const nnwSchoolApprovalDefault = {
  checked: false,
  title: '',
  content: null,
  internal_url: null,
  expandable: false,
  expanded: false,
  required: false,
  input_type: '',
  provider: '',
  slug: '',
  type: '',
};

export const nnwSchoolDateAndCodeDefault = {
  date: '',
  discountCode: '',
};

export const nnwSchoolInsurerDefault = {
  name: '',
  surname: '',
  withoutPesel: false,
  pesel: '',
  dateOfBirth: '',
  dateOfBirthInput: '',
  email: '',
  phone: '',
  country: countriesJson[0],
  city: { city: '' },
  zipCode: '',
  post: '',
  street: '',
  houseNr: '',
  apartmentNr: '',
  correspondenceAddress: false,
  correspondenceCountry: countriesJson[0],
  correspondenceZipCode: '',
  correspondenceCity: { city: '' },
  correspondencePost: '',
  correspondenceStreet: '',
  correspondenceHouseNr: '',
  correspondenceApartmentNr: '',
};

export const nnwSchoolInsuredDefault = {
  id: 1,
  name: '',
  surname: '',
  withoutPesel: false,
  pesel: '',
  dateOfBirth: '',
  dateOfBirthInput: '',
  parameters: [{
    name: 'Szkoła sportowa',
    parameter: 'sport-school',
    checked: false,
  }],
  types: [false],
  variant: 'nnw-school-1',
  sameAddressAsInsurer: true,
  country: countriesJson[0],
  city: { city: '' },
  zipCode: '',
  post: '',
  street: '',
  houseNr: '',
  apartmentNr: '',
};

export const nnwSchoolStatement = {
  slug: 'general-statement',
};

export const nnwSchoolSignalStatementDefault = {
  options: ['', ''],
  consent: null,
  validationOk: false,
  approvalStatement: false,
  slug: '',
};

export const useNnwSchoolStep1Store = defineStore('nnw-school-step1', {
  state(): FormState {
    return {
      step1: {
        isset: false,
        approvals: [nnwSchoolApprovalDefault],
        dateAndCode: nnwSchoolDateAndCodeDefault,
        insurer: nnwSchoolInsurerDefault,
        insured: [nnwSchoolInsuredDefault],
        statement: nnwSchoolStatement,
        signalStatement: nnwSchoolSignalStatementDefault,
        validations: {},
        validationsOk: {},
        validationsAllOk: false,
      },
    };
  },
  actions: {
    setStep1(value: BuyWizardFormStep1) {
      this.step1 = value;
      this.step1.isset = true;
    },
    setStep1Insurance1Price(value: InsurancePrice) {
      this.step1.insured[0].variant = value.variant;
      this.step1.insured[0].parameters = value.parameters;
    },
    setDiscountCode(value: string) {
      this.step1.dateAndCode.discountCode = value;
    },
    discountCodeReset() {
      this.step1.dateAndCode.discountCode = '';
    },
    async resetState() {
      this.step1 = {
        approvals: [nnwSchoolApprovalDefault],
        dateAndCode: nnwSchoolDateAndCodeDefault,
        insurer: nnwSchoolInsurerDefault,
        insured: [nnwSchoolInsuredDefault],
        statement: nnwSchoolStatement,
        signalStatement: nnwSchoolSignalStatementDefault,
        validations: {},
        validationsOk: {},
        validationsAllOk: false,
      };
    },
  },
  getters: {
    getStep1: (state: FormState) => state.step1,
    getDiscountCode: (state: FormState) => state.step1.dateAndCode.discountCode,
  },
  // persist: {
  //   storage: window.localStorage,
  // },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNnwSchoolStep1Store, import.meta.hot));
}
