import { useToast } from 'vue-toastification'
import { FormValidationWarningIconInverted } from '~/assets/img/icons/index.js'

export const setSuccessToast = (message: string, timeout: number|false|undefined) => {
  const toast = useToast()
  toast.success(message, {
    timeout,
    position: 'top-right',
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: false,
    icon: true,
    rtl: false,
  })
}

export const setErrorToast = (message: string, timeout: number|false|undefined) => {
  const toast = useToast()
  toast.error(message, {
    timeout,
    position: 'top-right',
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: false,
    icon: FormValidationWarningIconInverted,
    rtl: false,
  })
}
