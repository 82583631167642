import VueScrollTo from 'vue-scrollto'
import { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  app.use(VueScrollTo, {
    container: 'body',
    easing: 'ease-in',
    lazy: false,
    offset: 0,
    force: true,
    cancelable: true,
    x: false,
    y: true,
  })
}
