<template>
  <img
    v-if="success"
    class="validation__input--icon"
    :class="{'z-index-1': lowZIndex }"
    src="@/assets/img/icons/form-validation-success.svg?url"
    alt="success"
  >
  <img
    v-if="error"
    class="validation__input--icon validation__input--icon-error"
    src="@/assets/img/icons/form-validation-error.svg?url"
    alt="error"
    @click="handleClearError"
  >
</template>

<script setup lang="ts">
interface Props {
  success: boolean
  error: boolean
  type?: string
  lowZIndex?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  success: false,
  error: false,
  type: 'index',
  lowZIndex: false,
})

const { success, error, type, lowZIndex } = toRefs(props)

const emit = defineEmits(['clearError', 'update:modelValue'])

const handleClearError = () => {
  emit('clearError', {
    type: type.value,
  })
}
</script>
