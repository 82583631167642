<template>
  <div
    v-if="isValidationError.isError || errorMessage.message !== '' || successMessage.message !== ''"
    class="validation-frame"
    :class="{ 'validation-frame--success' : successMessage.message !== ''}"
  >
    <div v-if="isValidationError.isError" class="validation-frame__content">
      <img src="@/assets/img/icons/form-validation-warning-inverted.svg?url" alt="warning">
      <span class="text-white">
        {{ t('validations.validationFrameText') }}
        <br>
        {{ t('validations.validationFrameText2') }}
      </span>
    </div>

    <div v-if="errorMessage.message !== ''" class="validation-frame__content">
      <img src="@/assets/img/icons/form-validation-warning-inverted.svg?url" alt="warning">
      <span class="text-white" :class="{ 'text-wrap': errorMessage.message && errorMessage.message !== '' && errorMessage.message.length > 16 }">{{ errorMessage.message }}</span>
      <span class="text-white" />
    </div>

    <div v-if="successMessage.message !== ''" class="validation-frame__content">
      <img src="@/assets/img/icons/form-validation-success-inverted.svg?url" alt="success">
      <span class="text-white" :class="{ 'text-wrap': successMessage.message && successMessage.message !== '' && successMessage.message.length > 16 }">
        {{ successMessage.message }}
      </span>
      <span class="text-white" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '~/store/error';
import { useSuccessStore } from '~/store/success';

const { t } = useI18n();
const successStore = useSuccessStore();
const errorStore = useErrorStore();
const errorMessage = computed(() => errorStore.getErrorFromReq);
const isValidationError = computed(() => errorStore.getErrorFromVs);
const successMessage = computed(() => successStore.getSuccess);
</script>
