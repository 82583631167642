<template>
  <swiper
    :modules="modules"
    :breakpoints="{
      100: {
        slidesPerView: 2,
        spaceBetween: 125,
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 125,
      },
      350: {
        slidesPerView: 2,
        spaceBetween: 75,
      },
      360: {
        slidesPerView: 2,
        spaceBetween: 75,
      },
      400: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      490: {
        slidesPerView: 3,
        spaceBetween: 150,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 100,
      },
      767: {
        slidesPerView: 3,
        spaceBetween: 200,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 270,
      },
      1300: {
        slidesPerView: 5,
        spaceBetween: 230,
      },
      1500: {
        slidesPerView: 5,
        spaceBetween: 200,
      },
    }"
    :centered-slides="true"
    :pagination="false"
    :initial-slide="windowWidth <= sm ? 0 : 2"
    :loop="true"
    class="recommendations-layout recommendations-layout__swiper"
  >
    <swiper-slide v-for="(recommendation, index) in recommendations" :key="index">
      <RecommendationsCard :data="recommendation" />
    </swiper-slide>
    <div class="recommendations-layout__info">
      Publikowane opinie nie są weryfikowane
    </div>
  </swiper>
</template>

<script lang="ts">
import { A11y, EffectFade, Navigation, Pagination } from 'swiper';
import { useSwiper, useSwiperSlide } from 'swiper/vue';
import { useWindowWidth } from '~/composables';
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';

export default {
  name: 'RecommendationsContainer',
  setup() {
    const swiper = useSwiper();
    const swiperSlide = useSwiperSlide();
    const { windowWidth, sm } = useWindowWidth();
    const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;
    const recommendations = homepageData.recommendations;

    return {
      swiper,
      swiperSlide,
      modules: [EffectFade, Navigation, Pagination, A11y],
      recommendations,
      windowWidth,
      sm,
    };
  },
};
</script>
