<template>
  <img v-if="isAgentIcons && props.srcAgent" :src="props.srcAgent" :alt="props.alt ">
  <img v-else :src="props.src" :alt="props.alt ">
</template>

<script setup lang="ts">

import { useAssignedAgentStore } from '~/store/assigned-agent';

const assignedAgentStore = useAssignedAgentStore();

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  srcAgent: {
    type: String,
    required: true,
  },
  alt: String,
});

const isAgentIcons = computed(() => {
  return !!assignedAgentStore.agentPrimaryColor;
});

</script>
