import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';
import generatedRoutes from 'virtual:generated-pages';
import { setupLayouts } from 'virtual:generated-layouts';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createRouter, createWebHistory } from 'vue-router';
import VueSocialSharing from 'vue-social-sharing';
import Notifications from '@kyvg/vue3-notification';
import App from './App.vue';

const app = createApp(App);
const routes = setupLayouts(generatedRoutes);
export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve) => {
      if (savedPosition) {
        // Exists when Browser's back/forward pressed
        return savedPosition;
      } else if (to.hash) {
        // For anchors
        return { selector: to.hash };
      } else if (from.path === to.path) {
        // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
        return {};
      }
      // scroll to top by default
      return setTimeout(() => {
        resolve({ left: 0, top: 0, behavior: 'smooth' });
      }, 100);
    });
  },
  routes,
});
app.use(router);
app.use(Notifications);
Object.values(import.meta.globEager('./modules/*.ts')).map(i => i.install?.({ app, router, routes }));

const head = createHead();
app.use(head);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(VueSocialSharing);

app.mount('#app');
