<template>
  <div class="news-layout">
    <div class="news-layout__container news-container">
      <NewsCard
        v-for="news in homepageNews"
        :key="news.id"
        :data="news"
        :max-size="false"
      />
    </div>

    <MqResponsive target="xs" class="news-layout__read-more news-read-more">
      <button
        class="button button--primary button--radius button--information-buy"
        @click="handleRedirectToNews"
      >
        <span class="text-bold">{{ t("links.showOthers") }}</span>
      </button>
    </MqResponsive>

    <MqResponsive target="sm+" class="news-layout__read-more news-read-more">
      <RouterLink
        to="/aktualnosci"
        class="menu__link"
        :class="{ 'news-read-more-hovered--link': hover }"
        @mouseover="handleHover(true)"
        @mouseleave="handleHover(false)"
      >
        {{ t("links.showOthers") }}
      </RouterLink>
      <div
        class="news-read-more__arrow"
        :class="{ 'news-read-more-hovered--arrow': hover }"
        @mouseover="handleHover(true)"
        @mouseleave="handleHover(false)"
      >
        <UiImgAgentSvg name="icons/arrow-right.svg" alt="arrow" @click="handleRedirectToNews" />
      </div>
    </MqResponsive>
  </div>
</template>

<script setup lang="ts">
import { handleHoverFromOther } from '~/composables';
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';
const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;

const { t } = useI18n();
const router = useRouter();
const homepageNews = homepageData.news;

const hover = ref(false);
const handleHover = (value: boolean) => {
  handleHoverFromOther(hover, value);
};

const handleRedirectToNews = () => {
  router.push('/aktualnosci');
};
</script>
