<template>
  <div class="your-agent-layout">
    <div
      v-if="agent || windowWidth > sm"
      class="your-agent-layout__images"
    >
      <div class="your-agent-layout__images--agent">
        <picture v-if="!agent || !agent.avatar">
          <source
            :srcset="getYourAgentImageByGender('small_webp')"
            type="image/webp"
            :media="`(max-width: ${sm}px)`"
          >
          <source
            :srcset="getYourAgentImageByGender('medium_webp')"
            type="image/webp"
            :media="`(max-width: ${lg}px)`"
          >
          <source
            :srcset="getYourAgentImageByGender('large_webp')"
            type="image/webp"
            :media="`(min-width: ${lg}px)`"
          >
          <source
            :srcset="getYourAgentImageByGender('small_png')"
            type="image/png"
            :media="`(max-width: ${sm}px)`"
          >
          <source
            :srcset="getYourAgentImageByGender('medium_png')"
            type="image/png"
            :media="`(max-width: ${lg}px)`"
          >
          <source
            :srcset="getYourAgentImageByGender('large_png')"
            type="image/png"
            :media="`(min-width: ${lg}px)`"
          >
          <img
            :src="getYourAgentImageByGender('large_png')"
            :alt="data.leftImageAltText"
            :title="data.leftImageTitle"
            loading="lazy"
          >
        </picture>

        <img
          v-if="agent && agent.avatar"
          :src="agent.avatar"
          :alt="data.leftImageAltText"
          :title="data.leftImageTitle"
          loading="lazy"
        >
      </div>

      <div class="your-agent-layout__images--counter">
        <UiImgAgentSvg name="icons/your-agent-counter.svg?url" alt="counter" />
        <span class="your-agent-layout__images--counter-number">+14 000</span>
        <span class="your-agent-layout__images--counter-cooperating"> {{ t('other.agents') }}</span>
      </div>
    </div>

    <FormContainer v-if="!agent || agent && windowWidth > sm" :class="{'viviamo-agent-card': isViviamoAgent}" :agent-card="agent !== null">
      <template #uIcon>
        <UiImgAgentSvg v-if="!agent && windowWidth > sm" name="form-container/u-icon.svg?url" alt="i" loading="lazy" />
        <UiImgAgentSvg v-if="agent && windowWidth > sm" name="form-container/u.svg?url" alt="i" loading="lazy" />
        <UiImgAgentSvg v-if="!agent && windowWidth <= sm && !messageSent" name="form-container/u.svg?url" alt="i" loading="lazy" />
      </template>

      <template #main>
        <div
          v-if="agent"
          :class="windowWidth <= sm ? 'your-agent-card-mobile' : 'form-container__form'"
        >
          <YourAgentCard
            :data="agent"
          />
        </div>
        <div v-else class="form-container__form">
          <YourAgentForm @message-sent="handleMessageSent" />
        </div>
      </template>

      <template #iIcon>
        <img v-if="!agent && windowWidth > sm && !messageSent" src="@/assets/img/form-container/i-icon.svg?url" alt="i" loading="lazy">
        <img v-if="agent && windowWidth > sm && isViviamoAgent" src="@/assets/img/form-container/viviamo-bottom.svg?url" class="viviamo-agent-card__bottom-img" alt="i" loading="lazy">
        <img v-else-if="agent && windowWidth > sm" src="@/assets/img/form-container/i.svg?url" alt="i" loading="lazy">
        <img v-if="!agent && windowWidth <= sm && !messageSent" src="@/assets/img/form-container/i-for-mobile.svg?url" alt="i" loading="lazy">
        <img v-if="agent && windowWidth <= sm && isViviamoAgent" src="@/assets/img/form-container/viviamo-bottom.svg?url" class="viviamo-agent-card__bottom-img" alt="i" loading="lazy">
        <img v-else-if="agent && windowWidth <= sm" src="@/assets/img/form-container/i-for-mobile-agent.svg?url" alt="i" loading="lazy">
      </template>
    </FormContainer>

    <div v-if="agent && windowWidth <= sm" :class="{'viviamo-agent-card': isViviamoAgent}" class="your-agent-card-mobile">
      <div class="your-agent-card-mobile__u-icon">
        <img v-if="agent && windowWidth <= sm" src="@/assets/img/form-container/u-for-mobile-agent.svg?url" alt="u" loading="lazy">
      </div>

      <YourAgentCard
        :data="agent"
      />

      <div class="your-agent-card-mobile__i-icon">
        <img v-if="agent && windowWidth <= sm && isViviamoAgent" src="@/assets/img/form-container/viviamo-bottom.svg?url" class="viviamo-agent-card__bottom-img" alt="i" loading="lazy">
        <img v-else-if="agent && windowWidth <= sm" src="@/assets/img/form-container/i-for-mobile-agent.svg?url" alt="i" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useWindowWidth } from '~/composables';
import { useAssignedAgentStore } from '~/store/assigned-agent';

const { t } = useI18n();
const { windowWidth, sm, lg } = useWindowWidth();
const { agent } = storeToRefs(useAssignedAgentStore());

const isViviamoAgent = computed(() => agent.value?.is_viviamo);

const data = ref({
  leftImageAltText: 'Twój agent',
  leftImageTitle: 'Twój agent',
  images: {
    imageLeftMale: {
      original: '/assets/img/your-agent.png',
      original_png: '/assets/img/your-agent.png',
      large_png: '/assets/img/your-agent.png',
      medium_png: '/assets/img/your-agent.png',
      small_png: '/assets/img/your-agent.png',
      original_webp: '/assets/img/your-agent.webp',
      large_webp: '/assets/img/your-agent.webp',
      medium_webp: '/assets/img/your-agent.webp',
      small_webp: '/assets/img/your-agent.webp',
    },
    imageLeftFemale: {
      original: '/assets/img/your-agent-female.png',
      original_png: '/assets/img/your-agent-female.png',
      large_png: '/assets/img/your-agent-female.png',
      medium_png: '/assets/img/your-agent-female.png',
      small_png: '/assets/img/your-agent-female.png',
      original_webp: '/assets/img/your-agent-female.webp',
      large_webp: '/assets/img/your-agent-female.webp',
      medium_webp: '/assets/img/your-agent-female.webp',
      small_webp: '/assets/img/your-agent-female.webp',
    },
  },
});

const getYourAgentImageByGender = (breakpoint) => {
  if (agent.value && agent.value.gender && agent.value.gender === 'F') {
    return data.value.images.imageLeftFemale[breakpoint];
  } else {
    return data.value.images.imageLeftMale[breakpoint];
  }
};

const messageSent = ref(false);
const handleMessageSent = (value) => {
  messageSent.value = value;
};
</script>
