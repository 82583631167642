import { mustBeTrue } from '~/composables'

export const approvalRules = (required) => {
  return {
    checked: {
      ...(required.value && { mustBeTrue }),
    },
  }
}

export const controlRules = (noValidation) => {
  return {
    consent: {
      ...(!noValidation.value && { mustBeTrue }),
    },
  }
}
