<template>
  <source v-if="isAgentIcons && props.srcsetAgent" :srcset="props.srcsetAgent">
  <source v-else :srcset="props.srcset">
</template>

<script setup lang="ts">

import { useAssignedAgentStore } from '~/store/assigned-agent';

const assignedAgentStore = useAssignedAgentStore();

const props = defineProps({
  srcset: {
    type: String,
    required: true,
  },
  srcsetAgent: {
    type: String,
    required: false,
  },
  alt: String,
});

const isAgentIcons = computed(() => {
  return !!assignedAgentStore.agentPrimaryColor;
});

</script>
