import { helpers } from '@vuelidate/validators';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import { useErrorStore } from '~/store/error';

dayjs.extend(customParseFormat);
dayjs.extend(minMax);
dayjs.extend(isBetween);

export const peselDecode = (pesel: string) => {
  let dateOfBirth;
  let yearsOfPerson;
  let monthsOfPerson;
  let daysOfPerson;

  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month > 80) {
    year = year + 1800;
    month = month - 80;
  } else if (month >= 60) {
    year = year + 2200;
    month = month - 60;
  } else if (month >= 40) {
    year = year + 2100;
    month = month - 40;
  } else if (month >= 20) {
    year = year + 2000;
    month = month - 20;
  } else {
    year += 1900;
  }

  if (month >= 0 && month < 12 && day > 0 && day < 32) {
    dateOfBirth = new Date();
    dateOfBirth.setFullYear(year, month, day);

    const date = dayjs(dateOfBirth);
    yearsOfPerson = dayjs().diff(date, 'year');
    monthsOfPerson = dayjs().diff(date, 'month');
    daysOfPerson = dayjs().diff(date, 'day');
  } else {
    dateOfBirth = false;
  }

  // sex
  let sex;
  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    sex = 'm';
  } else {
    sex = 'k';
  }

  return {
    sex,
    date: dateOfBirth,
    years: yearsOfPerson,
    months: monthsOfPerson,
    days: daysOfPerson,
  };
};

const getAgeFromDate = (birthdayDate: Date) => {
  const date = dayjs(birthdayDate);
  return dayjs().diff(date, 'year');
};

export const validatePesel = (pesel: string) => {
  if (!helpers.req(pesel)) {
    return true;
  }

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  const controlNumber = parseInt(pesel.substring(10, 11));

  if (pesel.length === 11 && pesel !== '00000000000' && pesel !== '44444444444') {
    for (let i = 0; i < weights.length; i++) {
      sum += (parseInt(pesel.substring(i, i + 1)) * weights[i]);
    }

    sum = sum % 10;
    return (10 - sum) % 10 === controlNumber;
  } else {
    return false;
  }
};

export const validatePeselAge = (minAge: number, maxAge: number) => (pesel: string) => {
  if (!helpers.req(pesel)) {
    return true;
  }

  const peselDecoded = peselDecode(pesel);

  if (!peselDecoded.months) {
    return false;
  }

  const age = peselDecoded.months;

  return age >= minAge && age <= maxAge;
};

export const validateDiscountCode = (category: string) => async (code: string) => {
  if (!helpers.req(code)) {
    return true;
  }

  const isCodeOk = /([a-zA-Z]{2})+([0-9]{2})+/g.test(code);

  if (isCodeOk) {
    const res = await axios.post(`${import.meta.env./api}/v1/agents/`, {
      agent_code: code,
      permission: category,
    });
    return await res.data.data.permissions.includes(category) && res.data.data.code === code;
  }
};

export const mustBeTrue = (value: boolean) => value;

export const validateDate = (minDate: Date, maxDate: Date) => (date: string | Date) => {
  return dayjs(date).isBetween(minDate, dayjs(maxDate), 'date', '[]');
};

export const validateDateBetweenDates = (minDate: string, maxDate: string) => (date: string | Date) => {
  if (!date) {
    return true;
  }
  return dayjs(date).isBetween(dayjs(minDate), dayjs(maxDate));
};

export const validatePassword = (value: string) => {
  return /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[@$!%*#?&]).*$/g.test(value);
};

export const validateNip = (nip: string) => {
  if (!nip) {
    return true;
  }
  nip = nip.replace(/\s/g, '').replace(/-/g, '');
  if (nip.length !== 10) {
    return false;
  }

  if (!/^\d+$/.test(nip)) {
    return false;
  }

  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(nip.charAt(i)) * weights[i];
  }
  const controlDigit = sum % 11;

  if (controlDigit === 10) {
    return false;
  }

  if (parseInt(nip.charAt(9)) !== controlDigit) {
    return false;
  }

  return true;
};

export const validateRegon = (regon: string) => {
  if (!regon) {
    return true;
  }
  regon = regon.replace(/\s/g, '');
  if (regon.length !== 9 && regon.length !== 14) {
    return false;
  }

  if (!/^\d+$/.test(regon)) {
    return false;
  }

  const weights = [8, 9, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += parseInt(regon.charAt(i)) * weights[i];
  }
  let controlDigit = sum % 11;
  if (controlDigit === 10) {
    controlDigit = 0;
  }

  if (parseInt(regon.charAt(regon.length - 1)) !== controlDigit) {
    return false;
  }

  return true;
};

export const emailValidator = (value: string) => {
  return /^(?:[ĄąĆćĘęŁłŃńÓóŚśŹźŻżA-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ĄąĆćĘęŁłŃńÓóŚśŹźŻżA-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9](?:[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9-]*[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9])?\.)+[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9]{2,}(?:[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9-]*[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9-]*[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])$/i.test(value);
};

export const zipCodeValidator = (errorType: string) => (zipCode: string) => {
  if (!helpers.req(zipCode)) {
    return true;
  }
  const errorStore = useErrorStore();
  const errorFromZipCode = computed(() => errorStore.getErrorFromZipCode);

  return !(zipCode.length === 6 && errorFromZipCode.value.some(v => v.isError && v.type === errorType));
};

export const alphaValidator = (value: string) => {
  return /^[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z]*$/.test(value);
};

export const alphaDashValidator = (value: string) => {
  return /^[ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z-]*$/.test(value);
};
