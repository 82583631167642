<template>
  <img v-if="isAgentIcons" :src="'/assets/img/agent/' + props.name" :alt="props.alt ?? props.name">
  <img v-else :src="'/assets/img/unilink/' + props.name" :alt="props.alt ?? props.name">
</template>

<script setup lang="ts">

import { useAssignedAgentStore } from '~/store/assigned-agent';

const assignedAgentStore = useAssignedAgentStore();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  alt: String,
});

const isAgentIcons = computed(() => {
  return !!assignedAgentStore.agentPrimaryColor;
});

</script>
