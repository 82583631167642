
import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import type { CmsContent, SlugType } from '~/types/cms';

export const useCmsStore = defineStore('cms', () => {
  // STATE
  const cmsData = ref<CmsContent[]>([]);
  const dataDownloaded = ref<boolean>(false);

  // API

  const fetchDataFromApi = async () => {
    if (!dataDownloaded.value) {
      try {
        const res = await axios.get(`${import.meta.env./api}/v1/pages`, {});
        cmsData.value = res.data.data;
        dataDownloaded.value = true;
      } catch (error) {
        console.error(error);
        dataDownloaded.value = false;
      }
    }
  };

  // GETTERS
  const getData = (slug: SlugType): CmsContent | undefined => {
    return cmsData.value.find(v => v.slug === slug);
  };

  return {
    fetchDataFromApi,
    getData,
    dataDownloaded,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCmsStore, import.meta.hot));
}
