import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const lettersAndNumbersKeypress = (e) => {
  if (/[^ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z0-9]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const capitalLettersAndNumbersKeypress = (e) => {
  if (/[^ĄĆĘŁŃÓŚŹŻA-Z0-9]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const lettersKeypress = (e) => {
  if (/[^ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const lettersWithDashKeypress = (e) => {
  if (/[^ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z-]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const emailKeypress = (e) => {
  if (/[^ĄąĆćĘęŁłŃńÓóŚśŹźŻża-zA-Z0-9.@_-]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const numberKeypress = (e) => {
  if (/[^0-9]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const numberWithDashKeypress = (e) => {
  if (/[^0-9-]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const dateKeypress = (e) => {
  if (/[^0-9]/g.test(e.key)) {
    e.preventDefault();
  }
};

export const useDatepickerOptions = () => {
  const dpOptions = reactive({
    flow: ['calendar'],
    textInputOptions: {
      format: 'dd-MM-yyyy',
      openMenu: false,
      enterSubmit: true,
      tabSubmit: true,
    },
  });

  const dpOptionsYear = reactive({
    flow: ['year', 'month', 'calendar'],
    textInputOptions: {
      format: 'dd-MM-yyyy',
      openMenu: false,
      enterSubmit: true,
      tabSubmit: true,
    },
  });

  const dpOptionsMonth = reactive({
    flow: ['month', 'calendar'],
    textInputOptions: {
      format: 'dd-MM-yyyy',
      openMenu: false,
      enterSubmit: true,
      tabSubmit: true,
    },
  });

  const transitions = ref(
    {
      open: 'false',
      close: 'scale',
      next: 'slide',
      previous: 'slide',
    });

  return { dpOptions, dpOptionsYear, dpOptionsMonth, transitions };
};

export const useClearError = (args, state, v$, datepicker = null, reactive = false) => {
  if (((args.type === 'date' || args.type === 'dateOfBirth') && datepicker !== null && !reactive)) {
    datepicker.value.clearValue();
  } else if (reactive) {
    state[args.type] = '';
  } else {
    state.value[args.type] = '';
  }

  v$.value[args.type].$reset();
};

export const useUpdateDate = (state, date, dateInput, direction) => {
  if (direction === 'to-datepicker') {
    state.value[date] = new Date(dayjs(state.value[dateInput], 'DD-MM-YYYY'));
  }

  if (direction === 'to-input') {
    state.value[dateInput] = dayjs(state.value[date]).format('DD-MM-YYYY');
  }
};

export const formatPhoneForApi = (phone) => {
  return phone && phone.replace('+48 ', '').replaceAll('-', '').replaceAll(' ', '');
};

export const formatPhoneForDisplay = (phone) => {
  return phone && `+48 ${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 9)}`;
};

export const formatZipCodeForApi = (zipCode) => {
  return zipCode && zipCode.replace('-', '');
};

export const formatZipCodeForDisplay = (zipCode) => {
  return zipCode && `${zipCode.substring(0, 2)}-${zipCode.substring(2, 6)}`;
};
