<template>
  <div :id="props.id" class="validation">
    <img
      class="validation__message--icon"
      src="@/assets/img/icons/form-validation-warning.svg?url"
      alt="error"
    >
    <span class="validation__message--text">
      <slot>{{ t('validations.name') }}</slot>
    </span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: '',
  },
});
const { t } = useI18n();
</script>
