<template>
  <div class="recommendation-card">
    <div class="recommendation-card__header">
      <div class="recommendation-card__header--avatar" :class="getBgClassName">
        <span>{{ capitalizeFirstLetterOfEachWord(data.name.substr(0, 1)) }}</span>
      </div>
      <span class="recommendation-card__header--name"
        >{{ capitalizeFirstLetterOfEachWord(data.name) }}
        {{ capitalizeFirstLetterOfEachWord(data.surname.substr(0, 1)) }}.</span
      >
      <span class="recommendation-card__header--time">{{
        dayjs(data.date).fromNow()
      }}</span>
    </div>

    <span class="recommendation-card__content">
      {{ data.opinion }}
    </span>

    <div class="recommendation-card__rating">
      <img
        v-for="rating in Number(data.rank)"
        :key="rating"
        src="@/assets/img/icons/star.svg?url"
        alt="rating"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import pl from "dayjs/locale/pl";
import type { HomepageRecommendation } from "~/types/cms";
import { capitalizeFirstLetterOfEachWord } from "~/composables";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale(pl);

interface Props {
  data: HomepageRecommendation;
}
const props = withDefaults(defineProps<Props>(), {});
const { data } = toRefs(props);

const nameForBg = ref(data.value.name.toLowerCase());
const formatNameForBg = () => {
  switch (nameForBg.value[0]) {
    case "ó":
      nameForBg.value = nameForBg.value.replace("ó", "o");
      break;
    case "ł":
      nameForBg.value = nameForBg.value.replace("ł", "l");
      break;
    case "ń":
      nameForBg.value = nameForBg.value.replace("ń", "n");
      break;
    case "ż":
      nameForBg.value = nameForBg.value.replace("ż", "z");
      break;
    case "ź":
      nameForBg.value = nameForBg.value.replace("ź", "z");
      break;
    case "ć":
      nameForBg.value = nameForBg.value.replace("ć", "c");
      break;
    case "ę":
      nameForBg.value = nameForBg.value.replace("ę", "e");
      break;
    case "ś":
      nameForBg.value = nameForBg.value.replace("ś", "s");
      break;
  }
};

onBeforeMount(() => {
  formatNameForBg();
});

const getBgClassName = computed(() => {
  return `avatar-bg-${nameForBg.value.substr(0, 1).toLowerCase()}`;
});
</script>
