<template>
  <div :class="{'radio-approval' : inputType === 'radio' }">
    <div
      class="approval"
      :class="[
        { 'approval--bwf' : (inputType === 'check' && type.includes('bwf'))|| (inputType === 'check' && type.includes('payment')) },
        { 'approval--bvt' : inputType === 'check' && type.includes('bvt') },
        { 'approval--cp' : inputType === 'check' && type.includes('cp') },
        { 'approval--register' : inputType === 'check' && type.includes('register') },
        { 'approval--rememberMe' : inputType === 'check' && type.includes('rememberMe')},
      ]"
    >
      <div v-if="inputType === 'check'" class="approval__checkbox">
        <label :for="`${type}-${inputType}-control-${counter}`" class="control">
          <input
            :id="`${type}-${inputType}-control-${counter}`"
            v-model.lazy="state.checked"
            type="checkbox"
            required
            :disabled="disabled"
            @change="v$.checked.$touch()"
          >
          <div
            class="control__indicator"
            :class="[
              { 'control__indicator--primary' : primary },
              { 'control__indicator--primary-bwf' : type.includes('bwf') && primary || type.includes('register') && primary },
              { 'control__indicator--bwf' : type.includes('bwf') || type.includes('register') },
              { 'control__indicator--bvt' : type.includes('bvt') },
              { 'control__indicator--cp' : type.includes('cp') }
            ]"
          />
        </label>
      </div>

      <div v-if="inputType === 'radio'" class="approval__radio">
        <FormInputRadio
          v-model.lazy="state.checked"
          :disabled="disabled"
          :options="radioOptions"
          :type="type"
        />
      </div>

      <div
        class="approval__content"
        :class="[
          { 'approval__content--radio' : inputType === 'radio'},
          { 'approval__content--disabled': disabled },
          { 'approval__content--max' : inputType === 'check' && expanded },
          { 'approval__content--bwf' : (inputType === 'check' && type.includes('bwf')) || (inputType === 'check' && type.includes('bvt')) || (inputType === 'check' && type.includes('payment')) || (inputType === 'check' && type.includes('register')) },
          { 'approval__content--bvt' : inputType === 'check' && type.includes('bvt') }
        ]"
      >
        <label :for="`${type}-${inputType}-control-${counter}`" :class="{'cursor-pointer' : inputType === 'check' }">
          <span v-if="required"> ({{ t('common.obligatory') }}) </span>
          <slot name="title">
            <template v-if="!props.noCollapse">
              <span v-if="!state.expandable || (state.expandable && !state.expanded)" :class="{'text-primary': primary && counter === 0 } " v-html="state.title" />
              <span v-if="state.expandable && !state.expanded">...</span>
              <span v-if="state.expandable && state.expanded" :class="{'text-primary': primary && counter === 0 }" v-html="state.content" />
            </template>
            <template v-else>
              <span v-if="state.content" :class="{'text-primary': primary && counter === 0 }" v-html="state.content" />
              <span v-else :class="{'text-primary': primary && counter === 0 } " v-html="state.title" />

            </template>
          </slot>
        </label>

        <slot v-if="state.expandable && !props.noCollapse" name="expand">
          <span
            class="text-bold text-extra-small cursor-pointer"
            @click="handleExpand"
          >
            {{ state.expanded ? 'Zwiń' : 'Rozwiń' }}
            <img
              src="@/assets/img/icons/chevron-down.svg?url"
              alt="arrow"
              :class="{ 'rotate-180' : state.expanded }"
            >
          </span>
        </slot>

        <span
          v-if="v$.checked.hasOwnProperty('mustBeTrue') && v$.checked.$dirty && v$.checked.mustBeTrue.$invalid"
          class="text-extra-small text-invalid text-narrow"
        >
          <slot name="validationMsg">{{ t('validations.selectRequiredApproval') }}</slot>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { useVModelState } from '~/composables';
import { approvalRules } from '~/components/form/input/FormInputRules';

interface State {
  checked: boolean;
  required: number;
  slug: string;
}

interface Props {
  type: string;
  inputType: string;
  modelValue: State;
  counter?: number;
  noValidation?: boolean;
  primary?: boolean;
  required?: boolean;
  noExpand?: boolean;
  dirty?: boolean;
  disabled?: boolean;
  noCollapse?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'index',
  primary: false,
  required: false,
  noExpand: false,
  noValidation: false,
  dirty: false,
  counter: 0,
  disabled: false,
  noCollapse: false,
});

const { type, inputType, modelValue, counter, primary, required, noExpand, dirty, disabled } = toRefs(props);

const emit = defineEmits(['changedVs', 'update:modelValue']);

const { state } = useVModelState(modelValue.value, emit);

const expanded = ref (!!noExpand.value || type.value.includes('bvt') || type.value.includes('payment'));

const { t } = useI18n();

const radioOptions = ref([
  t('inputs.approvalRadioOption1'),
  t('inputs.approvalRadioOption2'),
]);

const rules = approvalRules(required);
const v$ = useVuelidate(rules, state);

const handleExpand = () => {
  if (!disabled.value) { state.value.expanded = !state.value.expanded; }
};

watch(v$, (value, prevValue) => {
  if (value !== prevValue) {
    emit('changedVs', {
      vS: v$.value,
      validationOk: v$.value.$errors.length === 0 && !v$.value.$invalid,
      validationErrors: v$.value.$dirty && v$.value.$errors.length > 0,
      type: type.value,
      counter: counter.value,
    });
  }
});

watch(() => disabled.value, (nV, oV) => {
  if (nV !== oV && nV === true) { state.value.checked = null; }
});

onMounted(() => {
  dirty.value && v$.value.checked.$touch();
});

onUpdated(() => {
  dirty.value && v$.value.checked.$touch();
});

</script>
